import { Elm } from "../Login";

// Polyfill for NodeList.forEach
// Will be included to babel-polyfill when it starts to use core-js 3
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

document.addEventListener("DOMContentLoaded", () => {
  const target = document.getElementById("MemberPortalLogin");
  const flags = {
    initializationUrl: `https://staging.oma.tradeka.fi/fi/kayttajat/kirjaudu.json?`,
    domain: "https://staging.oma.tradeka.fi/"
  };

  if (target) {

    let params = [];

    if(target.getAttribute('data-kind')) {
      params.push('kind=' + target.getAttribute('data-kind'));
    }

    if(target.getAttribute('data-locale')) {
      params.push('locale=' + target.getAttribute('data-locale'));
    }

    flags.initializationUrl += params.join('&');

    target.childNodes.forEach(node => target.removeChild(node));
    const node = document.createElement("div");
    target.appendChild(node);

    const loginEmbed = Elm.Login.init({ node, flags });
    loginEmbed.ports.statusCode.subscribe(status => {
      if(status == 422) window.location.href = flags.domain;
    });

    loginEmbed.ports.redirectTo.subscribe(url => {
      window.location.href = url;
    });
  } else {
    /*
    console.error(
      `Unable to find DOM element for embedding login!
      Create element with id 'MemberPortalLogin' and try again`
    );
    */
  }
});
